
import { mapWritableState } from 'pinia'
import { useDcmStore } from '~/stores/dcm'
import { useMarketStore } from '~/stores/market'

export default {
  name: 'MPLandingHeader',
  setup() {
    const marketStore = useMarketStore()
    return {
      marketStore,
    }
  },
  data() {
    return {
      showMenu: false,
      showSubMenu: false,
    }
  },
  computed: {
    ...mapWritableState(useDcmStore, ['init_header_slider']),
    initSlider() {
      return this.init_header_slider
    },
    setMinOrderAmountWarning() {
      if (!this.marketStore.cart?.total_amount) return false
      if (this.marketStore.minOrderAmount) {
        return (
          this.marketStore.cart?.total_amount >= this.marketStore.minOrderAmount
        )
      } else {
        return true
      }
    },
  },
  mounted() {
    if (!this.marketStore.minOrderAmount)
      this.marketStore.getMinCheckoutCartAmount()
  },
  methods: {
    setShowMenu(showMenu) {
      this.showMenu = showMenu
    },
    setShowSubMenu(showSubMenu) {
      this.showSubMenu = showSubMenu
    },
    mouseLeave() {
      setTimeout(() => {
        this.showMenu = false
        this.showSubMenu = false
      }, 50)
    },
  },
}
