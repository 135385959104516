
import setDataLayerCategoryOpen from '@/mixins/setDataLayer'
import { setUrl } from '@/utilities'
import { mapWritableState } from 'pinia'
import { useDcmStore } from '~/stores/dcm'
import { useMarketStore } from '@/stores/market'
export default {
  name: 'SmartProductCategoriesMenu',
  mixins: [setDataLayerCategoryOpen],
  setup() {
    const marketStore = useMarketStore()
    return {
      marketStore,
    }
  },
  data() {
    return {
      hover: false,
    }
  },
  async fetch() {
    if (!this.smart_category?.length) {
      const dcmStore = useDcmStore()
      await dcmStore.getSmartCategory()
    }
  },
  computed: {
    ...mapWritableState(useDcmStore, ['smart_category', 'prom_prod_title']),
    listSmartCategories() {
      return this.smart_category || []
    },
    hasProducts() {
      return !!this.smart_category.length
    },
  },
  methods: {
    setUrl,
    src(category) {
      return category.images?.[4]
    },
  },
}
