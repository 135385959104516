
import { useDcmStore } from '@/stores/dcm'
import { storeToRefs } from 'pinia'

export default {
  name: 'MPLandingLinkBadges',
  setup() {
    const dcmStore = useDcmStore()
    const { mainPageLandingLinkBadges } = storeToRefs(dcmStore)
    return { mainPageLandingLinkBadges }
  },
  computed: {
    firstBanner() {
      const banner = this.mainPageLandingLinkBadges?.items?.find(
        item => item.seq_no === 1,
      )
      const image = banner?.images
        ? banner?.images?.['1']
        : `/images/linkBadges/smartphones_${this.$i18n.locale}.webp`
      const defaultUrl =
        this.$i18n.locale === 'az'
          ? 'https://umico.az/categories/3-mobil-telefonlar-ve-smartfonlar'
          : 'https://umico.az/ru/categories/3-mobilnye-telefony-i-smartfony'
      const url = banner?.action_url || defaultUrl
      return { image, url }
    },
    secondBanner() {
      const banner = this.mainPageLandingLinkBadges?.items?.find(
        item => item.seq_no === 2,
      )
      const image = banner?.images
        ? banner?.images?.['1']
        : `/images/linkBadges/loyalty-${this.$i18n.locale}.webp`
      const defaultUrl = `https://umico.az${
        this.$i18n.locale === 'az' ? '' : '/ru'
      }/loyalty-program`
      const url = banner?.action_url || defaultUrl
      return { image, url }
    },
    thirdBanner() {
      const banner = this.mainPageLandingLinkBadges?.items?.find(
        item => item.seq_no === 3,
      )
      const image = banner?.images
        ? banner?.images?.['1']
        : `/images/linkBadges/birkart_umico_${this.$i18n.locale}.jpg`
      const host = `birbank-card.umico.az${
        this.$i18n.locale === 'az' ? '/' : '/ru'
      }`
      const params = '?utm_source=marketplace&utm_medium=banner'
      const defaultUrl = `https://${host}${params}`
      const url = banner?.action_url || defaultUrl
      return { image, url }
    },
  },
}
